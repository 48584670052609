export default () => ({
  required: {
    required: true,
    message: useT('bcd65'),
    trigger: 'submit',
  },
  email: {
    type: 'email',
    message: useT('bcd66'),
    trigger: 'submit',
  },
  url: {
    type: 'url',
    message: useT('bcd43'),
    trigger: 'submit',
  },
  password: [
    { required: true, message: useT('bcd67'), trigger: 'submit' },
    { strength: 'minLength', regexp: true, pattern: /^.{8,}$/, message: useT('bcd68'), trigger: 'submit' },
    { strength: 'uppercaseOrNumber', regexp: true, pattern: /(?=.*[A-Z])|(?=.*[0-9])/, message: useT('bcd69'), trigger: 'submit' },
  ],
  passwordConfirm: (confirmValue) => {
    return [{
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(useT('bcd70')));
        } else if (value !== confirmValue.value) {
          callback(new Error(useT('bcd71')));
        } else {
          callback();
        }
      },
      trigger: 'blur',
    }];
  },
  startDate: (endDate) => {
    return {
      validator: (rule, value) => {
        return value ? useDayjs(value).isBefore(endDate.value) : true;
      },
      message: useT('bcd72'),
      trigger: 'submit',
    };
  },
  endDate: (startDate) => {
    return {
      validator: (rule, value) => {
        return value ? useDayjs(value).isAfter(startDate.value) : true;
      },
      message: useT('bcd72'),
    };
  },
});
